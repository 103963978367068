<template>
  <main id="realTime">
    <PageHeader :title="$t('realTime.title')" :items="breadcrumb" />

    <b-row v-if="usrname">
      <b-col>
        <b-card>
          <b-form
            name="form-login"
            action="https://www.smartenergy.com.br/login.asp"
            method="post"
            target="_blank"
          >
            <div class="form-row" align="center">
              <input
                type="text"
                name="user"
                v-model="usrname"
                style="display: none"
              />
            </div>

            <div class="form-row" align="center">
              <input
                type="password"
                name="password"
                v-model="psswd"
                style="display: none"
              />
            </div>

            <div class="form-row" align="center">
              <input
                class="btn btn-primary"
                type="submit"
                value="Acesso Medição Detalhada"
                name="evento"
              />
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="this.emptyMessage" class="text-center p-2">
        <b-card>
          <h5>MEDIÇÕES NÃO ENCONTRADAS.</h5>
        </b-card>
      </b-col>
      <b-col v-if="this.InitialLoading" class="text-center p-2">
        <b-card>
          <b-spinner v-if="this.InitialLoading" label="Spinning"></b-spinner>
        </b-card>
      </b-col>
      <b-col v-if="!this.InitialLoading && !this.emptyMessage">
        <b-card>
          <b-form
            @submit.prevent="handleMeasurement(selectedPontoMedicao, selected)"
            inline
            class="d-flex justify-content-center"
          >
            <label for="inline-form-custom-select-pref">
              {{ $t('realTime.ponto_medicao') }}
            </label>

            <b-form-select
              id="inline-form-custom-select-pref"
              class="m-2"
              v-model="selectedPontoMedicao"
              :options="valuesPontoMedicao"
            />

            <label for="inline-form-custom-select-pref">{{
              $t('realTime.month')
            }}</label>

            <b-form-select
              id="inline-form-custom-select-pref"
              class="m-2"
              v-model="selected"
              :options="values"
            />

            <b-button variant="primary" type="submit">{{
              $t('realTime.load')
            }}</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-col v-if="this.loading === true" class="text-center p-2">
      <b-card>
        <b-spinner v-if="this.loading === true" label="Spinning"></b-spinner>
      </b-card>
    </b-col>

    <b-col
      style="margin-bottom: -25px"
      v-if="!loading && isMeasurements"
      class="text-center p-2"
    >
      <b-card
        style="height: 3rem; padding: 0rem; background: #004f59"
        class="text-center"
      >
        <h4 style="margin-top: -10px; color: #fff">
          {{ $t('realTime.consumption') }}
        </h4>
      </b-card>
    </b-col>
    <b-row>
      <b-col v-if="!loading && isMeasurements">
        <b-card>
          <b-table
            v-if="dataConsumo.length > 0"
            striped
            responsive
            hover
            :items="dataConsumo"
            :fields="fieldsConsumo"
          >
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="loading"></b-col>
      <b-col v-if="!loading && isMeasurements">
        <b-card style="max-width: 100%" class="mb-2">
          <ChartConsumo
            :monthly="monthlyReport"
            :name="companySelected"
          ></ChartConsumo>
        </b-card>
      </b-col>
    </b-row>

    <b-col
      style="margin-bottom: -25px; margin-top: 10px"
      v-if="!loading && isMeasurements"
      class="text-center p-2"
    >
      <b-card
        style="height: 3rem; padding: 0rem; background: #004f59"
        class="text-center"
      >
        <h4 style="margin-top: -10px; color: #fff">
          {{ $t('realTime.active_demand') }}
        </h4>
      </b-card>
    </b-col>
    <b-row>
      <b-col v-if="!loading && isMeasurements">
        <b-card>
          <b-table
            v-if="dataDemandaAtiva.length > 0"
            striped
            responsive
            hover
            :items="dataDemandaAtiva"
            :fields="fieldsDemandaAtiva"
          >
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="!loading && isMeasurements">
        <b-card>
          <ChartDemandaAtiva
            :monthly="monthlyReport"
            :name="companySelected"
          ></ChartDemandaAtiva>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>
<script>
import ChartDemandaAtiva from '../../components/chartDemandaAtiva';
import ChartConsumo from '../../components/chartConsumo';
import VueCookies from 'vue-cookies';
import PageHeader from '@/components/page-header';
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import dayjs from 'dayjs';
import Vue from 'vue';

import { getMeasurements } from './realTime.services';
import { getAuth } from '../auth/auth.helpers';
import { i18n } from '../../main';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

const { gestal } = getAuth();
const CONFIG = new Config();
const gestalMedicao = gestal.gestalInfo.medicao;

dayjs.locale('pt-br');
Vue.use(VueCookies);
Vue.use(VueToast);

export default {
  name: 'realTime',
  components: {
    PageHeader,
    ChartConsumo,
    ChartDemandaAtiva
  },
  data() {
    return {
      title: i18n.tc('realTime.title'),
      selectedPontoMedicao: '',
      companySelected: '',
      medicaoAtual: '',
      selected: '',
      valuesPontoMedicao: [],
      dataDemandaAtiva: [],
      dataMeasurement: [],
      monthlyReport: [],
      dataConsumo: [],
      allMedicoes: [],
      values: [],
      InitialLoading: false,
      isDemandaAtiva: false,
      isMeasurements: false,
      emptyMessage: false,
      isConsumo: false,
      loading: false,
      currentClient: null,
      idMedicao: null,
      url: null,
      medicoes: gestalMedicao,
      cookies: gestal.cookies,
      gestal: gestal,
      config: CONFIG,
      fieldsDemandaAtiva: [
        {
          key: 'demanda',
          label: i18n.tc('realTime.total_demand')
        },
        {
          key: 'ponta',
          label: i18n.tc('realTime.tip'),
          sortable: false
        },
        {
          key: 'foraPonta',
          label: i18n.tc('realTime.off_peak'),
          sortable: false
        }
      ],
      fieldsConsumo: [
        {
          key: 'consumo',
          label: i18n.tc('realTime.total')
        },
        {
          key: 'ponta',
          label: i18n.tc('realTime.tip'),
          sortable: false
        },
        {
          key: 'foraPonta',
          label: i18n.tc('realTime.off_peak'),
          sortable: false
        }
      ]
    };
  },
  beforeMount: function () {
    this.usrname = gestal.gestalInfo.login;
    this.psswd = gestal.gestalInfo.senha;
  },
  created() {
    this.InitialLoading = true;
    this.allMedicoes = this.medicoes.map((item) => item.idMedicao);
    this.selectedPontoMedicao = this.medicoes[0].idMedicao;
    this.medicaoAtual = this.medicoes[0].idMedicao;

    this.valuesPontoMedicao = this.medicoes.map((item) => {
      return {
        value: item.idMedicao,
        text: item.nomeMedicao
      };
    });

    getMeasurements(this.allMedicoes)
      .then((response) => {
        if (response.length == 0) {
          this.emptyMessage = true;
          this.InitialLoading = false;
          this.loading = false;
        }
        console.log('RESPONSE TESTE: ', response);
        this.dataMeasurement = response.flat(Infinity);

        const medicaoFiltrada = response.filter(
          (item) => item.idMedicao === this.medicaoAtual
        );

        this.values = medicaoFiltrada[0].relatoriosMensais.map(
          (item, index) => {
            return {
              value: index.toString(),
              text: dayjs(item.mesReferencia).format('MMMM/YYYY')
            };
          }
        );

        this.monthlyReport = medicaoFiltrada[0].relatoriosMensais;
        this.selected = (this.values.length - 1).toString();
        this.InitialLoading = false;

        setTimeout(() => {
          this.handleMeasurement(this.selectedPontoMedicao, this.selected);
          this.isMeasurements = true;
        }, 200);
      })
      .catch(() => {
        this.InitialLoading = false;
        this.loading = false;
        console.log('ERRO NO RESPONSE.');
        Vue.$toast.error('Erro ao carregar medições', {
          position: 'top-right',
          duration: 5000
        });
      });
  },
  computed: {
    breadcrumb: () => [
      {
        text: 'Home',
        href: '/'
      },
      {
        text: i18n.tc('realTime.title'),
        active: true
      }
    ],

    fields: () => [
      {
        key: 'nomeMedicao',
        label: i18n.tc('realTime.name')
      },
      {
        key: 'idMedicao',
        label: 'ID',
        sortable: false
      },
      {
        key: 'button',
        label: i18n.tc('realTime.open'),
        sortable: false
      }
    ]
  },
  methods: {
    async handleMeasurement(item, selected) {
      this.loading = true;

      const data = this.dataMeasurement.filter(
        (itemData) => itemData.idMedicao === this.selectedPontoMedicao
      );

      setTimeout(() => {
        this.loading = false;
        this.isMeasurements = true;

        this.values = data[0].relatoriosMensais.map((itemValues, index) => {
          return {
            value: index.toString(),
            text: dayjs(itemValues.mesReferencia).format('MMMM/YYYY')
          };
        });

        const { relatoriosMensais } = data[0];

        const companyFiltered = this.valuesPontoMedicao.filter(
          (item1) => item1.value === item
        );

        this.companySelected = companyFiltered[0].text;
        this.monthlyReport = relatoriosMensais[selected];

        this.dtDemandaAtiva = [
          relatoriosMensais[selected].demandaAtiva.demandaAtivaConsolidada
        ];

        const {
          consumoTotal,
          consumoTotalForaPonta,
          consumoTotalPonta
        } = relatoriosMensais[selected].consumo.consumoConsolidado;

        const {
          demandaContratadaPonta,
          demandaContratadaForaPonta,
          demandaMaximaToleradaPonta,
          demandaMaximaToleradaForaPonta,
          demandaMaximaRegistradaPonta,
          demandaMaximaRegistradaForaPonta,
          horaDemandaMaximaPonta,
          horaDemandaMaximaForaPonta
        } = relatoriosMensais[selected].demandaAtiva.demandaAtivaConsolidada;

        this.dataDemandaAtiva = [
          {
            demanda: 'Demanda Contratada',
            ponta:
              demandaContratadaPonta !== null
                ? `${demandaContratadaPonta.toLocaleString('pt-BR')} kW`
                : '-',
            foraPonta:
              demandaContratadaForaPonta !== null
                ? `${demandaContratadaForaPonta.toLocaleString('pt-BR')} kW`
                : '-'
          },
          {
            demanda: 'Demanda Máxima Tolerada',
            ponta:
              demandaMaximaToleradaPonta !== null
                ? `${demandaMaximaToleradaPonta.toLocaleString('pt-BR')} kW`
                : '-',
            foraPonta:
              demandaMaximaToleradaForaPonta !== null
                ? `${demandaMaximaToleradaForaPonta.toLocaleString('pt-BR')} kW`
                : '-'
          },
          {
            demanda: 'Demanda Máxima Registrada',
            ponta:
              demandaMaximaRegistradaPonta !== null &&
              horaDemandaMaximaPonta !== null
                ? `${demandaMaximaRegistradaPonta.toLocaleString(
                    'pt-BR'
                  )} kW (${dayjs(
                    horaDemandaMaximaPonta.toLocaleString('pt-BR')
                  ).format('DD/MM/YY HH:mm')}h)`
                : '-',
            foraPonta:
              demandaMaximaRegistradaForaPonta !== null &&
              horaDemandaMaximaForaPonta !== null
                ? `${demandaMaximaRegistradaForaPonta.toLocaleString(
                    'pt-BR'
                  )} kW (${dayjs(
                    horaDemandaMaximaForaPonta.toLocaleString('pt-BR')
                  ).format('DD/MM/YY HH:mm')}h)`
                : '-'
          }
        ];

        this.dataConsumo = [
          {
            consumo:
              consumoTotal !== null
                ? `${consumoTotal.toLocaleString('pt-BR')} MWh`
                : '-',
            ponta:
              consumoTotalPonta !== null
                ? `${consumoTotalPonta.toLocaleString('pt-BR')} MWh`
                : '-',
            foraPonta:
              consumoTotalForaPonta !== null
                ? `${consumoTotalForaPonta.toLocaleString('pt-BR')} MWh`
                : '-'
          }
        ];
      });
    }
  }
};
</script>
<style>
@media (max-width: 536px) {
  .btn {
    width: 128px;
    margin: 5px;
  }
}

@media (max-width: 1150px) {
  .col-table,
  .col-chart {
    width: 100%;
  }
}

td {
  font-size: 13px;
}

th {
  font-size: 13px;
}

.col-table {
  width: 30%;
}

.col-chart {
  width: 70%;
}
</style>
