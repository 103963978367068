import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import axios from 'axios';
import dayjs from 'dayjs';
import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

import { getAuth } from '../auth/auth.helpers';

Vue.use(VueToast);
dayjs.locale('pt-br');

const CONFIG = new Config();

export const getMeasurements = async (selectedPontoMedicao) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      const { token } = getAuth();
      var data = JSON.stringify({
        idMedicao: selectedPontoMedicao
      });

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}/gestal/relatorios`,
        headers: {
          Bearer: token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }, 300);
  });
};
